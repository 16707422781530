import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { db } from '../firebase_config.js';
import LoadingScreen from './Loading';
import { collection, addDoc } from "firebase/firestore"; 


const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [guest, setGuest] = useState(null);
    const [dia, setDia] = useState(new Date());
    const [evento, setEvent] = useState("casamento");
    const [known, setKnown] = useState("Redes Sociais");
    const [comentario, setComentario] = useState("");
    const [data, setData] = useState(false);
    const sent = Date();
    
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const handleCheck = () => {
        setData(!data);
    }

    const handleSubmit = async (event) => {
        const formData = {
            name,
            email,
            phone,
            address,
            guest,
            dia,
            evento,
            known,
            comentario,
            sent
          };
    
        if(name !== "" && email !== "" && address !== "" && guest !== null && data){
            event.preventDefault();

            setLoading(true)
            addDoc(collection(db, "form"), formData)
            .then(() => {
                alert("Os seus dados foram enviados")
                navigate('/')
            })
            .catch((error) => {
                alert("Tente novamente")
                console.log(error)
            })
            .finally(() => {
                setLoading(false);
            })
            
        }
        
            
    }


    return(
    

            <div className='flex flex-col items-center justify-center'>
                        
                <div className='md:w-1/4 w-1/2'>
                    <form form action="https://formsubmit.co/bikesthecandybar@gmail.com" method="POST">
                            <input type="hidden" name="_captcha" value="false" className='hidden'/>
                            <input type="hidden" name="_next" value="https://bikesthecandybar.pt" className='hidden'/>
                            <div className='mb-5'>
                                <label className='mb-3 text-slate-900'>Nome *</label>
                                <input placeholder='Nome' maxLength="30" type='text' name="name" onChange={(event) => setName(event.target.value)} className='w-full border border-gray-400 rounded-xl bg-white px-4 py-2 focus:shadow-md focus:border-soft-green' required></input>
                            </div>
                            <div className='mb-5'>
                                <label className='mb-3 text-slate-900'>Email *</label>
                                <input placeholder='Email' maxLength="80" type='text' name="email" onChange={(event) => setEmail(event.target.value)}  className='w-full border border-gray-400 rounded-xl bg-white px-4 py-2 focus:shadow-md focus:border-soft-green' required></input>
                            </div>
                            <div className='mb-5'>
                                <label className='mb-3 text-slate-900'>Número de telefone *</label>
                                <input placeholder='Número de telefone' maxLength="15" type='text' name="phone" onChange={(event) => setPhone(event.target.value)}  className='w-full border border-gray-400 rounded-xl bg-white px-4 py-2 focus:shadow-md focus:border-soft-green' required></input>
                            </div>
                            <div className='mb-5'>
                                <label className='mb-3 text-slate-900'>Morada *</label>
                                <input placeholder='Local do Evento' maxLength="80" type='text' name="address" onChange={(event) => setAddress(event.target.value)}  className='w-full border border-gray-400 rounded-xl bg-white px-4 py-2 focus:shadow-md focus:border-soft-green' required></input>
                            </div>
                            <div className='mb-5'>
                                <label className='mb-3 text-slate-900'>Número de Convidados *</label>
                                <input placeholder='Número de Convidados' type='number' name="guests" onChange={(event) => setGuest(event.target.value)}  className='w-full border border-gray-400 rounded-xl bg-white px-4 py-2 focus:shadow-md focus:border-soft-green' required></input>
                            </div>
                            <div className='mb-5 flex flex-col'>
                                <label className='text-slate-900'>Data *</label>
                                <DatePicker dateFormat='dd/MM/yyyy' name="dia" selected={dia}  onChange={(dia) => setDia(dia)}  className='xs:w-3/5 md:w-2/5 w-full border border-gray-400 rounded-xl bg-white px-4 py-2 focus:shadow-md focus:border-soft-green text-base' required/>
                            </div>
                            <div className='mb-5 flex flex-col'>
                                <label className='text-slate-900'>Tipo de Evento</label>
                                <select name="evento" type='text' defaultValue="casamento" onChange={(event) => setEvent(event.target.value)} className='xs:w-3/5 md:w-2/5 w-full border border-gray-400 rounded-xl bg-white px-4 py-2 focus:shadow-md focus:border-soft-green'>
                                    <option value="casamento" >Weeding Breakfast</option>
                                    <option value="batizado" >Batizado</option>
                                    <option value="aniversario" >Aniversário</option>
                                    <option value="evento corporativo" >Eventos Corporativos</option>
                                    <option value="picnic" >Picnic</option>
                                    <option value="despedida" >Despedida de Solteiro</option>
                                    <option value="mini weeding" >Mini Weeding</option>
                                </select>
                            </div>
                            <div className='mb-5 flex flex-col'>
                                <label className='text-slate-900'>Como chegou até nós</label>
                                <select name="known" type='text' defaultValue="Redes Sociais" onChange={(event) => setKnown(event.target.value)} className='xs:w-3/5 md:w-2/5 w-full border border-gray-400 rounded-xl bg-white px-4 py-2 focus:shadow-md focus:border-soft-green'>
                                    <option value="redes sociais" >Redes Sociais</option>
                                    <option value="feiras noivos" >Feiras Noivos</option>
                                    <option value="recomendaçao" >Recomendação</option>
                                </select>
                            </div>
                            <div className='mb-5'>
                                <label className='mb-3 text-slate-900'>Comentários</label>
                                <textarea name='comentario' maxLength='300' onChange={(event) => setComentario(event.target.value)} placeholder='Coloque aqui outros aspetos relevantes do seu evento'className='w-full h-32 border border-gray-400 rounded-xl bg-white px-4 py-2 focus:shadow-md focus:border-soft-green'></textarea>
                            </div>
                            <div>
                                <input type="checkbox" name="politica-dados" onChange={handleCheck} required/>
                                <label className='mx-2'>Aceito os <Link to='/politica-de-tratamento-de-dados'><u>termos e condições</u></Link></label>
                            </div>
                            <div className='mt-10 mb-20 flex justify-center'>
                                <button type="submit" className="rounded-xl p-2 w-40 self-center text-soft-green bg-slate-100 hover:bg-soft-green hover:text-slate-100 hover:scale-110">Enviar</button>
                            </div>
                    
                    </form>
                </div>  
                </div>  
    )

    }

export default Contact;